// 产品
import productInfo from './productInfo'
// 表格数据
import table from './table'
// 发货方式
import deliveryWay from './deliveryWay'
// 配送
import distribution from './distribution'
// 自提
import pickUpInfo from './pickUpInfo'
// 基础
import base from './base'
// 记录
import record from './record'
// 使用记录
import recordList from './recordList'
// 购买方
import buyInfo from './buyInfo'
// 售后处理
import afterProcessing from './afterProcessing'
// 物流
import logistics from './logistics'
// api数据
import apiTool from '@/command/apiTool'
// 入住信息
import checkInfo from './checkInfo'
// 人员信息表格
import rosterTable from './rosterTable'
// 优惠券
import coupons from './coupons'
// 退款信息
import retuenInfo from './retuenInfo'
// 核销信息
import tableList from './tableList'
// 退款信息
import tableRefund from './tableRefund'
// 退款&房间核销
import writeOff from './writeOff'

import DrawerForm from '../components/DrawerForm'
// 组合套餐表格
import tablePackage from './tablePackage'
// 景点门票表格
import tableTickets from './tableTickets'
// 景点门票核销表格
import tableTicketsSelect from './tableTicketsSelect'
// 景点门票基本信息
import baseTicket from './baseTicket'
// 景点门票实名制用户列表
import tableTicketsInfo from './tableTicketsInfo'
// 景点门票第三方票务
import thirdPartyTicket from './thirdPartyTicket'
// 景点门票记录
import recordTicket from './recordTicket'
// 开票信息
import invoices from './invoices'
// 开票接受信息
import invoicesAccept from './invoicesAccept'

// 船票 
 // 基础信息
import ticketBase from './ticketBase'
// 资金信息
import ticketMoney from './ticketMoney'
// 船票信息
import ticketInfo from './ticketInfo'
import ticketDriver from './ticketDriver'
import ticketCustom from './ticketCustom'
import ticketRecord from './ticketRecord'
// 研学
import tableEvents from './tableEvents'
import baseEvents from './baseEvents'

function execute(arr, params) {
  let arrs = []
  arr.forEach((e) => {
    if (e.render) {
      arrs.push(e.render(params))
    } else {
      const ret = e(params)
      if (Array.isArray(ret)) {
        arrs = arrs.concat(ret)
      } else {
        arrs.push(ret)
      }
    }
  })
  return arrs
}

// 打开模式
function open({ data, params = {}, executeFun, foot, success, onHidden,title='订单信息' } = {}) {
  const vm = apiTool.showDrawer({
    title,
    width: '900px',
    view: DrawerForm,
    viewProps: {
      form: data,
      formProps: {
        layout: 'horizontal',
        cols: 24,
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
      },
      data: (form) => {
        return execute(executeFun, {
          ...params,
          form,
          success: vm.onOk,
          setHidden: vm.onCancel,
        })
      },
    },
    onHidden,
    success,
    foot,
  })
}

/**
 * 退款
 */
function refund(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 购买方信息
      buyInfo,
      // 售后处理
      afterProcessing,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认处理',
          type: 'danger',
          onClick: submit,
        },
      ]
    },
  })
}

/**
 * 房间核销
 */
function roomWriteOff(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 购买方信息
      buyInfo,
      // 售后处理
      afterProcessing,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认核销',
          isPop: true,
          popTitle: (h) => (
            <span>
              确认用户已经到店，核销掉用户
              <br />
              购买的房间
            </span>
          ),
          icon: (h) => <a-icon style="color:#f00" theme="filled" type="info-circle" />,
          type: 'danger',
          onClick: submit,
        },
      ]
    },
  })
}

/**
 * 取货
 */
function puikUp(data,flag) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 配送方式
      pickUpInfo,
      // 基础信息
      base,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      if (flag){
        return []
      }
      return [
        {
          name: '返回',
          onClick: close,
          display:!(data.data.status == 4 || data.data.status == 5 || data.data.status == 6)
        },
        {
          name: '确认已取货',
          type: 'primary',
          onClick: submit,
          display:!(data.data.status == 4 || data.data.status == 5 || data.data.status == 6)
        },
      ].filter(d=>d.display)
    },
  })
}

/**
 * 发货
 */
function delivery(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 发货方式
      deliveryWay,
      // 配送方式
      distribution,
      // 基础信息
      base,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,        
        },
        {
          name: '确认发货',
          type: 'primary',
          onClick: submit,
        },
      ] 
    },
  })
}

/**
 * 已完成订单
 */
function completed(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 配送方式
      distribution,
      // 基础信息
      base,
      // 物流信息
      logistics,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          display: data.data.status === '6' && data.data.fromType === '1',
          name: '删除',
          onClick: submit,
          type: 'danger',
        },
        {
          display: data.data.status === '1' && data.data.fromType === '13',
          name: '立即退款',
          onClick: submit,
          type: 'danger',
        },
        {
          display: true,
          name: '取消',
          onClick: close,
        },
      ].filter((e) => e.display)
    },
  })
}
/**
 * 商品核销
 * @param {*} data
 */
function completedCancel(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 配送方式
      distribution,
      // 基础信息
      base,
      // 物流信息
      logistics,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      let isShow = data.data.userCodeVOList ? data.data.userCodeVOList.filter((e) => e.status === '0').length : 0
      return [
        {
          display: true,
          name: '取消',
          onClick: close,
        },
        {
          display: isShow,
          name: '确认核销',
          type: 'danger',
          onClick: submit,
        },
      ].filter((e) => e.display)
    },
  })
}

/**
 * 确认收货
 */
function received(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 配送方式
      distribution,
      // 基础信息
      base,
      // 物流数据
      logistics,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '确认收货',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}

/**
 * 支付
 */
function close(data) {
  open({
    ...data,
    executeFun: data.executeFun || [
      // 表格数据
      table,
      // 配送方式
      distribution,
      // 基础信息
      base,
      // 记录
      record,
    ],
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close,
        },
        {
          name: '关闭订单',
          type: 'primary',
          onClick: submit,
        },
      ]
    },
  })
}
export default {
  productInfo,
  recordTicket,
  tableTicketsInfo,
  thirdPartyTicket,
  baseTicket,
  tablePackage,
  tableTickets,
  tableTicketsSelect,
  table,
  tableList,
  rosterTable,
  deliveryWay,
  distribution,
  base,
  record,
  recordList,
  buyInfo,
  afterProcessing,
  logistics,
  coupons,
  retuenInfo,
  checkInfo,
  // 打开弹窗
  open,
  // 发货
  delivery,
  // 已取货
  puikUp,
  // 退款
  refund,
  // 已完成
  completed,
  // 确认收货
  received,
  // 支付
  close,
  // 执行函数
  execute,
  completedCancel,
  tableRefund,
  writeOff,
  roomWriteOff,
  invoices,
  invoicesAccept,
  ticketBase,
  ticketMoney,
  ticketInfo,
  ticketDriver,
  ticketCustom,
  ticketRecord,
  baseEvents,tableEvents,
  tableEvents
}
